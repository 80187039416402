import { getUserData } from '@/auth/utils'

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  ...(getUserData().is_superuser ? [
    {
      title: 'Users',
      route: 'users',
      icon: 'UsersIcon',
    },
  ] : []),
]
